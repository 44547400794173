import React from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { State } from 'store/interfaces';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import BookTestDrivePopUp from '../build-vehicle/action-footer/BookTestDrivePopUp';
import ShowRoomVisitPopUp from '../build-vehicle/action-footer/ShowRoomVisitPopUp';
import RequestCallbackPopUp from '../build-vehicle/action-footer/RequestCallbackPopUp';
import { RouteKeys } from 'containers/routes/route-keys';
import FinanceCalculatorPopUp from './popup/FinanceCalculatorPopUp';
import {
  ShowRoomDetailsModel,
  allVehicleModelActions,
  AllVehicleStateModel,
  showRoomListActions,
  commonActions,
  AppSettingsStateViewModel,
  api,
  AppSettingsHtmlDirectionAction,
  AppSettingsLanguageAction,
} from 'alg-ecom-frontend-core';
import Scrollspy from 'react-scrollspy'

export const customStylesPopup: ReactModal.Styles = {
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    maxWidth: '1140px',
    width: '100%',
    border: '0',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    padding: '0',
    margin: '20px auto',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflow: 'auto',
  },
};

interface CustomProps {
  isMenuOpen: boolean;
  fullHeader?: boolean;
  transparent?: boolean;
  requestCallbackPopUpOpen: boolean;
  bookTestDrivePopupOpen: boolean;
  downloadBrochurePopupOpen: boolean;
  requestQuotePopupOpen: boolean;
  sheduleShoroomVisitPopupOpen: boolean;
  toggleMenu: typeof commonActions.toggleMenu;
  toggleRequestCallback: typeof commonActions.toggleRequestCallback;
  toggleBookTestDrive: typeof commonActions.toggleBookTestDrive;
  toggleDownloadBrochure: typeof commonActions.toggleDownloadBrochure;
  toggleRequestQuote: typeof commonActions.toggleRequestQuote;
  toggleSheduleShowroomVisit: typeof commonActions.toggleSheduleShowroomVisit;
  toggleFinancePopUp: typeof commonActions.toggleFinancePopUp;
  financePopupOpen: boolean;
  showroomsList: ShowRoomDetailsModel[];
  vehicleModels: AllVehicleStateModel;
  getVehicleModelsList: typeof allVehicleModelActions.getVehicleModelsList;
  getShowRoomList: typeof showRoomListActions.getShowRoomList;
  appSettingsState: AppSettingsStateViewModel;
}
const Header: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams<{
    lang: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const handleLanguage = async (lng: string, isRemoveQuery: boolean) => {
    if (
      props?.appSettingsState?.data &&
      props?.appSettingsState?.data?.languagesList?.length > 0
    ) {
      const data = props?.appSettingsState?.data?.languagesList.find(
        (item) => item.languageLcidstring === lng
      );
      if (data) {
        await api.setLanguageID(data.languageID);
        await dispatch(AppSettingsLanguageAction(data.languageID));
        await dispatch(
          AppSettingsHtmlDirectionAction(lng === 'en' ? 'ltr' : 'rtl')
        );
        // if(lng)
        const urlSplit = url.split('/');
        if (urlSplit.length > 0 && urlSplit[1] === lang) {
          history.replace({
            pathname: url.replace(lang, lng),
          });
          window.location.reload();
        }
      }
    }
  };
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [subMenu, openSubMenu] = React.useState(false);
  const [scrollHeader, setScrollHeader] = React.useState(false);
  // const [scrollSec, setScrollSec] = React.useState<scrollSections | undefined>();
  const onVisitPopupCancel = () => {
    props.toggleSheduleShowroomVisit();
  };
  const onCallbackPopupCancel = () => {
    props.toggleRequestCallback();
  };
  const onDownloadBrochureCancel = () => {
    props.toggleDownloadBrochure();
  };
  const onRequestQuoteCancel = () => {
    props.toggleRequestQuote();
  };
  const onTestDrivePopupCancel = () => {
    props.toggleBookTestDrive();
  };
  const onFinancePopupCancel = () => {
    props.toggleFinancePopUp();
  };
  const onFinancePopupClick = () => {
    props.toggleFinancePopUp();
  };

  const renderFinanceModal = () => {
    return (
      <ReactModal
        isOpen={props.financePopupOpen}
        contentLabel="financePopupOpen"
        style={customStylesPopup}
        onRequestClose={onFinancePopupCancel}
        shouldCloseOnOverlayClick={true}
      >
        {/* {props.productData?.productDetails !== undefined && ( */}
        <FinanceCalculatorPopUp
          onPopupCancel={onFinancePopupCancel}
          // productID={1}
          showroomsList={props.showroomsList}
        // productDetails={props.productData?.productDetails}
        />
        {/* )} */}
      </ReactModal>
    );
  };

  React.useEffect(() => {
    if (
      props.vehicleModels?.modelsList === undefined ||
      props.vehicleModels?.modelsList?.length === 0
    ) {
      props.getVehicleModelsList();
    }
    if (
      props.showroomsList === undefined ||
      props.showroomsList?.length === 0
    ) {
      props.getShowRoomList();
    }
  }, []);

  // const renderTestDriveModal = () => {
  //   return (
  //     <ReactModal
  //       isOpen={props.bookTestDrivePopupOpen}
  //       contentLabel="Test Drive"
  //       style={customStylesPopup}
  //       onRequestClose={onTestDrivePopupCancel}
  //       shouldCloseOnOverlayClick={true}
  //     >
  //       <BookTestDrivePopUp onPopupCancel={onTestDrivePopupCancel} />
  //     </ReactModal>
  //   );
  // };
  // const renderVisitModal = () => {
  //   return (
  //     <ReactModal
  //       isOpen={props.sheduleShoroomVisitPopupOpen}
  //       contentLabel="Showroom Visit"
  //       style={customStylesPopup}
  //       onRequestClose={onVisitPopupCancel}
  //       shouldCloseOnOverlayClick={true}
  //     >
  //       <ShowRoomVisitPopUp onPopupCancel={onVisitPopupCancel} />
  //     </ReactModal>
  //   );
  // };
  // const renderCallbackModal = () => {
  //   return (
  //     <ReactModal
  //       isOpen={props.requestCallbackPopUpOpen}
  //       contentLabel="Callback"
  //       style={customStylesPopup}
  //       onRequestClose={onCallbackPopupCancel}
  //       shouldCloseOnOverlayClick={true}
  //     >
  //       <RequestCallbackPopUp
  //         onPopupCancel={onCallbackPopupCancel}
  //         title={'Request a callback'}
  //       />
  //     </ReactModal>
  //   );
  // };
  // const renderDownloadBrochureModal = () => {
  //   return (
  //     <ReactModal
  //       isOpen={props.downloadBrochurePopupOpen}
  //       contentLabel="Download brochure"
  //       style={customStylesPopup}
  //       onRequestClose={onDownloadBrochureCancel}
  //       shouldCloseOnOverlayClick={true}
  //     >
  //       <RequestCallbackPopUp
  //         onPopupCancel={onDownloadBrochureCancel}
  //         title={'Download brochure'}
  //       />
  //     </ReactModal>
  //   );
  // };
  // const renderRequestQuoteModal = () => {
  //   return (
  //     <ReactModal
  //       isOpen={props.requestQuotePopupOpen}
  //       contentLabel="Request aquote"
  //       style={customStylesPopup}
  //       onRequestClose={onRequestQuoteCancel}
  //       shouldCloseOnOverlayClick={true}
  //     >
  //       <RequestCallbackPopUp
  //         onPopupCancel={onRequestQuoteCancel}
  //         title={'Request a quote'}
  //       />
  //     </ReactModal>
  //   );
  // };

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 400 && (url === '/en/' || url === '/ar/')) {
      setScrollHeader(true);
    } else {
      setScrollHeader(false);
    }
  });

  return (
    <>
      <header
        className={classnames({
          'border-bottom d-flex flex-column': true,
          active: props.isMenuOpen,
          headerHome: props.transparent,
          headerFix: scrollHeader === true,
        })}
      >
        <div className="header-bottom position-relative">
          <div
            className={`${props.fullHeader ? 'container-fluid' : 'container'
              } px-lg-3 px-0`}
          >
            <div
              className={classnames({
                'row no-gutters justify-content-between': true,
                'align-items-center': scrollHeader === true,
              })}
            >
              <div className="col-lg-auto col-12 d-flex align-items-center py-lg-1 py-3 px-lg-0 px-3 logo-block">
                <div className="row no-gutters align-items-center w-100">
                  <div className="col-lg-auto col d-lg-block d-none">
                    <Link
                      to="/"
                      className="d-inline-block"
                      onClick={() => props.toggleMenu()}
                    >
                      {scrollHeader === true ? (
                        <img
                          src={require('assets/images/logo.svg')}
                          className="logo-white py-1 img-fluid"
                          alt="Bronco"
                        />
                      ) : (
                        <img
                          src={require('assets/images/logo-white.svg')}
                          className="logo-white py-1 img-fluid"
                          alt="Bronco"
                        />
                      )}
                      <img
                        src={require('assets/images/logo.svg')}
                        className="logo py-1 img-fluid"
                        alt="Bronco"
                      />
                    </Link>
                  </div>
                  <div
                    className="col-auto d-lg-none d-inline-flex menu-close"
                    onClick={() => props.toggleMenu()}
                  >
                    <i className="icon-close text-muted cursor-pointer py-1"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg col-12 main-menu-outer position-lg-static">
                {scrollHeader === false && (
                  <div className="header-top font-xs py-3 mb-1 d-lg-block d-none text-gray-700">
                    <div className="row gutter-12 justify-content-end">
                      <div className="col-auto font-weight-semibold font-xs d-inline-flex align-items-center">
                        <i className="icon-phone mr-2 font-xxxs"></i>{t('common.Have an enquiry')}{' '}<a href="tel:1898900" className="ml-2">1898900</a>
                      </div>
                      <div className="col-auto">
                        <div className="pl-4 border-left font-sm d-flex">
                          <a
                            href="https://twitter.com/FordAlghanim"
                            target="_blank"
                            className="text-gray-700"
                          >
                            <i className="icon-twitter mr-2 pr-1"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/fordalghanim/"
                            target="_blank"
                            className="text-gray-700"
                          >
                            <i className="icon-instagram mr-2 pr-1"></i>
                          </a>
                          <a
                            href="https://www.facebook.com/FordAlGhanim/"
                            target="_blank"
                            className="text-gray-700"
                          >
                            <i className="icon-facebook mr-2 pr-1"></i>
                          </a>
                          <a
                            href="https://www.youtube.com/c/FordAlghanim"
                            target="_blank"
                            className="text-gray-700"
                          >
                            <i className="icon-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col-auto d-lg-flex d-none align-items-center">
                        {i18n.languages[0] !== 'ar' && (
                          <a
                            href="javascript:void(0)"
                            className="border-left pl-4 font-weight-bold text-primary"
                            onClick={() => handleLanguage('ar', true)}
                          >
                            عربى
                          </a>
                        )}
                        {i18n.languages[0] !== 'en' && (
                          <a
                            href="javascript:void(0)"
                            className="border-left pl-4 font-weight-bold"
                            onClick={() => handleLanguage('en', true)}
                          >
                            English
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-wrap justify-content-lg-end">
                  <div className="d-lg-none w-100">
                    <div className="row mx-0 align-items-center py-3 border-bottom">
                      <div className="col-6 text-muted font-normal">
                        Language
                      </div>
                      <div className="col-6 px-0 text-right">
                        {i18n.languages[0] !== 'ar' && (
                          <a
                            href="javascript:void(0)"
                            className="px-3 font-weight-bold text-dark py-1"
                            onClick={() => handleLanguage('ar', true)}
                          >
                            عربى
                          </a>
                        )}
                        {i18n.languages[0] !== 'en' && (
                          <a
                            href="javascript:void(0)"
                            className="px-3 font-weight-bold text-dark py-1"
                            onClick={() => handleLanguage('en', true)}
                          >
                            English
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <ul
                    className={classnames({
                      'list-unstyled mb-0 main-menu d-lg-flex top-0 zIndex-9 align-items-center':
                        true,
                      active: props.isMenuOpen,
                    })}
                  >
                    <li className="col-lg-auto col-12 order-lg-last auth-link mb-lg-0 mb-4">
                      <Link
                        to={`/${lang}${RouteKeys.MyAccount}`}
                        onClick={() => props.toggleMenu()}
                        className="d-flex align-items-center"
                      >
                        <span className="d-flex align-items-center">
                          <i className="icon-user mr-2 font-xl d-lg-block d-none"></i>
                          <i className="icon-user mr-3 font-xxxl d-lg-none text-muted"></i>
                          {t('common.Account')}{' '}
                        </span>
                      </Link>
                    </li>
                    <Scrollspy items={['home', 'models', 'features', 'contact-us']} currentClassName="is-current" offset={-60}>
                      <li>
                        <Link
                          to={`/${lang}${RouteKeys.Home}#home`}
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => props.toggleMenu()}
                        >
                          <span className="d-flex align-items-baseline">
                            {t('common.Home')}
                          </span>
                          <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${lang}${RouteKeys.Home}#models`}
                          className={`d-flex align-items-center justify-content-between`}
                          onClick={() => props.toggleMenu()}
                        >
                          <span className="d-flex align-items-baseline">
                            {t('common.Models')}
                          </span>
                          <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${lang}${RouteKeys.Home}#features`}
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => props.toggleMenu()}
                        >
                          <span className="d-flex align-items-baseline">
                            {t('common.Features')}
                          </span>
                          <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${lang}${RouteKeys.Home}#gallery`}
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => props.toggleMenu()}
                        >
                          <span className="d-flex align-items-baseline">
                            {t('common.gallery')}
                          </span>
                          <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${lang}${RouteKeys.Home}#contact-us`}
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => props.toggleMenu()}
                        >
                          <span className="d-flex align-items-baseline">
                            {t('common.Contact Us')}
                          </span>
                          <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                        </Link>
                      </li>
                    </Scrollspy>
                    <li>
                      <Link
                        to={`/${lang}${RouteKeys.TrimLEvel.replace(
                          ':modelCode',
                          'Bronco'
                        )}`}
                        className="d-flex align-items-center justify-content-between"
                        onClick={() => props.toggleMenu()}
                      >
                        <span className="d-flex align-items-baseline">
                          {t('common.Reserve')}{' '}
                        </span>
                        <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        href="javascript:void(0);"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-baseline">
                          Contact Us
                        </span>
                        <i className="icon-chevron-right icon-flip-rtl font-xs d-lg-none"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none mt-auto">
          <div className="row no-gutters px-3 py-4 border-top font-xxl">
            <div className="col-auto py-2">
              <i className="icon-phone mr-4 d-inline-block pt-1"></i>
            </div>
            <div className="col-auto py-2">
              <span className="d-block text-muted line-height-normal">
                {t('common.Have an enquiry')}
              </span>
              <a href="tel:1898900" className="text-gray-900">1898900</a>
            </div>
          </div>
          <div className="row align-items-center mx-0 font-xl py-4 social-media border-top">
            <a
              href="https://www.facebook.com/FordAlGhanim/"
              target="_blank"
              onClick={() => props.toggleMenu()}
              className="col-auto d-inline-flex text-dark"
            >
              <i className="icon-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/fordalghanim/"
              target="_blank"
              onClick={() => props.toggleMenu()}
              className="col-auto d-inline-flex text-dark"
            >
              <i className="icon-instagram"></i>
            </a>
            <a
              href="https://twitter.com/FordAlghanim"
              target="_blank"
              onClick={() => props.toggleMenu()}
              className="col-auto d-inline-flex text-dark"
            >
              <i className="icon-twitter"></i>
            </a>
            {/* <a
              href="javascript:void(0);"
              onClick={() => props.toggleMenu()}
              className="col-auto d-inline-flex text-dark"
            >
              <i className="icon-linkedin"></i>
            </a> */}
            <a
              href="https://www.youtube.com/c/FordAlghanim"
              target="_blank"
              onClick={() => props.toggleMenu()}
              className="col-auto d-inline-flex text-dark"
            >
              <i className="icon-youtube"></i>
            </a>
          </div>
        </div>
      </header>
      {/* {renderTestDriveModal()}
      {renderVisitModal()}
      {renderCallbackModal()}
      {renderDownloadBrochureModal()}
      {renderRequestQuoteModal()} */}
      {renderFinanceModal()}
    </>
  );
};

const mapActionsToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      dispatch,
      toggleMenu: commonActions.toggleMenu,
      toggleRequestCallback: commonActions.toggleRequestCallback,
      toggleBookTestDrive: commonActions.toggleBookTestDrive,
      toggleDownloadBrochure: commonActions.toggleDownloadBrochure,
      toggleRequestQuote: commonActions.toggleRequestQuote,
      toggleSheduleShowroomVisit: commonActions.toggleSheduleShowroomVisit,
      toggleFinancePopUp: commonActions.toggleFinancePopUp,
      getVehicleModelsList: allVehicleModelActions.getVehicleModelsList,
      getShowRoomList: showRoomListActions.getShowRoomList,
    },
    dispatch
  );
};

const mapStateToProps = (state: State) => {
  return {
    isMenuOpen: state.commonState.headerMenuOpen,
    bookTestDrivePopupOpen: state.commonState.bookTestDrivePopupOpen,
    requestCallbackPopUpOpen: state.commonState.requestCallbackPopUpOpen,
    downloadBrochurePopupOpen: state.commonState.downloadBrochurePopupOpen,
    requestQuotePopupOpen: state.commonState.requestQuotePopupOpen,
    sheduleShoroomVisitPopupOpen:
      state.commonState.sheduleShoroomVisitPopupOpen,
    financePopupOpen: state.commonState.financePopupOpen,
    showroomsList: state.showRoomState.showroomsList,
    vehicleModels: state.allVehicleState,
    appSettingsState: state.appSettingsState,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Header);
