import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';

interface CustomProps {
    displayFooter?: boolean;
}

const Footer: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
    const { t, i18n } = useTranslation();
    const { lang } = useParams<{
        lang: string;
    }>();
    const handleLanguage = (selectlang: string) => {
        i18n.changeLanguage(selectlang);
    };
    return (
        <>
            <footer className={classnames({
                'bg-dark py-5': true,
                'd-lg-block d-none': props.displayFooter === false
            })} id="contact-us">
                <div className="container py-lg-5">
                    <div className="row py-md-3 pb-2">
                        <div className="col-xl col-12 text-md-left text-center mb-xl-0 mb-4 pb-xl-0 pb-3">
                            <img src={require('assets/images/footer-logo.png')} alt="Bronco" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-12 mb-sm-0 mb-4 text-md-left text-center">
                            <h6 className="text-white mb-3">Ford Alghanim Shuwaikh</h6>
                            <p className="text-white font-sm line-height-24">11st Shuwaikh. Industrial Area.,<br />
                                Kuwait,<br />
                                Telephone: <a href="tel:1898900" className="text-white">1898900</a></p>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12 text-md-left text-center">
                            <h6 className="text-white mb-3">Ford Alghanim Fahaheel</h6>
                            <p className="text-white font-sm line-height-24">Fahaheel Block 5,<br />
                                Al Dabous st.Plot 166,<br />
                                Kuwait,<br />
                                Telephone: <a href="tel:1898900" className="text-white">1898900</a></p>
                        </div>
                        <div className="col-xl-auto col-md col-12 my-md-0 my-5">
                            <div className="row gutter-12 justify-content-md-end justify-content-center text-white font-xl py-2 ">
                                <div className="col-auto">
                                    <a href="https://www.facebook.com/FordAlGhanim/" target="_blank" className="text-white"><i className="icon-facebook"></i></a>
                                </div>
                                <div className="col-auto">
                                    <a href="https://twitter.com/FordAlghanim" target="_blank" className="text-white"><i className="icon-twitter"></i></a>
                                </div>
                                <div className="col-auto">
                                    <a href="https://www.youtube.com/c/FordAlghanim" target="_blank" className="text-white"><i className="icon-youtube"></i></a>
                                </div>
                                <div className="col-auto">
                                    <a href="https://www.instagram.com/fordalghanim/" target="_blank" className="text-white"><i className="icon-instagram"></i></a>
                                </div>
                            </div>
                            <div className="row gutter-10 justify-content-md-end justify-content-center font-normal py-2">
                                <div className="col-auto">
                                    <Link to={`/${lang}${RouteKeys.Privacy}`} className="text-white">Privacy Policy</Link>
                                </div>
                                <div className="col-auto text-muted">|</div>
                                {/* <div className="col-auto">
                            <a href="#" className="text-white">Privacy Rights</a>
                        </div>
                        <div className="col-auto text-muted">|</div> */}
                                <div className="col-auto">
                                    <Link to={`/${lang}${RouteKeys.Terms}`} className="text-white">Terms and Conditions</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-white font-normal text-md-left text-center mt-xl-0 mt-md-3 mt-0">&copy; 2021 Ford Alghanim</div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
