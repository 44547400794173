import React from 'react';
import {
  numberWithCommas,
  BuildPriceProductModel,
} from 'alg-ecom-frontend-core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
interface CustomProps {
  product: BuildPriceProductModel;
  modelYear: number;
  onClick: any;
}
const ProductItem: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onProductClick = () => {
    props.onClick(props.product.modelCode);
  };
  return (
    <div className="productBox productBox--sm col-12 col-sm-6 col-md-4 border-top">
      <figure className="d-flex align-items-center justify-content-center m-0 position-relative">
        {/* <Link to={`/trim-level/${props.modelYear}/${props.product.modelCode}/`}> */}
        <img
          src={
            props.product.productImage !== ''
              ? props.product.productImage
              : require('assets/images/product/car.png')
          }
          className="img-fluid position-relative zIndex-1"
          alt=""
        // onClick={() => onProductClick()}
        />
        {/* </Link> */}
      </figure>
      <figcaption className="mb-md-4 mb-3">
        <h4 className="text-uppercase mb-3 font-base">{props.product.productTitle}</h4>
        <div className=" mb-3">
          <p className="font-normal text-muted mb-1">
            Starting at {props.product.productCurrency}{' '}
            {numberWithCommas(props.product.productPrice)}
          </p>
        </div>

        <button
          onClick={() => onProductClick()}
          className="btn btn-primary font-weight-bold font-sm text-uppercase position-relative px-4 productBox__select"
        >
          Build Yours
        </button>
      </figcaption>
    </div>


  );
};

export default ProductItem;
