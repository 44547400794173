import React, { Component, Dispatch } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { authActions as authUserActions } from 'alg-ecom-frontend-core';
import { State } from 'store/interfaces';
import Header from '../header';
import Footer from '../footer';
import { RouteKeys } from './route-keys';

interface CustomProps {
  component: any;
  isUserAuthenticated: () => boolean;
  redirectSave: (pathname: string) => void;
  exact?: boolean;
  path?: string;
  showFooter: boolean;
  avoidHeader?: boolean;
  fullHeader?: boolean;
}

// class AuthenticateRoute extends Component<CustomProps> {
//   render() {
//     const {
//       isUserAuthenticated,
//       redirectSave,
//       component,
//       ...rest
//     } = this.props;

//     return (
//       <Route
//         {...rest}
//         render={(props) => {
//           // if (!isUserAuthenticated()) {
//           //   redirectSave(props.location.pathname);
//           // }
//           if (isUserAuthenticated()) {
//             return (
//               <>
//                 {!this.props.avoidHeader && (
//                   <Header fullHeader={this.props.fullHeader} />
//                 )}
//                 <this.props.component {...props} />
//                 {this.props.showFooter === true && <Footer />}
//               </>
//             );
//           }
//           return (
//             <Redirect
//               to={{
//                 pathname: '/auth',
//               }}
//               from={props.location.pathname}
//             />
//           );
//         }}
//       />
//     );
//   }
// }

const AuthenticateRoute: React.FunctionComponent<CustomProps> = (props) => {
  const { isUserAuthenticated, redirectSave, component, ...rest } = props;

  const { lang } = useParams<{
    lang: string;
  }>();
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (isUserAuthenticated()) {
          return (
            <>
              {!props.avoidHeader && <Header fullHeader={props.fullHeader} />}
              <props.component {...renderProps} />
              {props.showFooter === true && <Footer />}
            </>
          );
        }
        return (
          <Redirect
            to={{
              pathname: `/${lang}${RouteKeys.Auth}`,
            }}
            from={renderProps.location.pathname}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: State) => ({
  isUserAuthenticated: () => state.authState.token != null,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  redirectSave: (to: string) => {
    dispatch(authUserActions.redirectSave(to));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateRoute);
