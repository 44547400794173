import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import Select from 'react-select';
import SelectInput from 'components/SelectInput';
import { bindActionCreators } from 'redux';
import { State } from 'store/interfaces';
import { connect } from 'react-redux';
import { Range, getTrackBackground } from 'react-range';
import { FinanceModelCart, BankDetailsModel } from 'alg-ecom-frontend-core';
import { useTranslation } from 'react-i18next';

const PaymentForm: React.FunctionComponent<{
  onSubmit: (data: any) => void;
  onCancel: any;
  banks: BankDetailsModel[];
  isUpdating?: boolean;
  default?: FinanceModelCart;
  errorMessage?: string;
  isEdit?: boolean;
  netPrice: number;
  productCurrency: string;
}> = (props) => {
  const {
    control,
    errors,
    register,
    handleSubmit,
    setValue,
    watch,
  } = useForm<FinanceModelCart>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // defaultValues: props?.default,
  });

  const [selectedBank, setSelectedBank] = React.useState<
    BankDetailsModel | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.default) {
      if (props.banks?.length > 0) {
        const bank = props.banks.find(
          (item) => item.bankID === props.default?.bankID
        );
        if (bank) {
          setSelectedBank(bank);
          setPMT(props.default?.monthlyPayment);
        }
      }
    }
  }, []);

  const { t } = useTranslation();

  const [PMT, setPMT] = React.useState(0);
  const onChangeBank = (id: number) => {
    const bank = props.banks.find((item) => item.bankID === id);
    setValue('downPayment', bank?.minDownPayment);
    setValue('term', bank?.maximumTenure);
    if (bank) {
      const term = bank?.maximumTenure;
      const P = props.netPrice - bank.minDownPayment;
      const R = bank.annualInterestRate / (100 * 12);
      const pmt = (P * R * Math.pow(1 + R, term)) / (Math.pow(1 + R, term) - 1);
      setPMT(pmt);
      setValue('monthlyPayment', pmt);
      setValue('interestRate', bank.annualInterestRate);
    } else {
      setPMT(0);
      setValue('monthlyPayment', 0);
      setValue('interestRate', 0);
    }
    setSelectedBank(bank);
    updateCart();
  };
  const calcMonthlyPayment = () => {
    if (selectedBank) {
      const downPayment = watch('downPayment');
      const term = watch('term');
      const P = props.netPrice - downPayment;
      const R = watch('interestRate') / (100 * 12);
      const pmt = (P * R * Math.pow(1 + R, term)) / (Math.pow(1 + R, term) - 1);
      setPMT(pmt);
      setValue('monthlyPayment', pmt);
    } else {
      setPMT(0);
      setValue('monthlyPayment', 0);
    }
    updateCart();
  };
  const updateCart = () => {
    const financeData: FinanceModelCart = {
      term: 0,
      bankID: 0,
      monthlyPayment: 0,
      downPayment: 0,
      bankName: '',
      interestRate: 0,
      effectiveInterestRate: 0,
    };
    financeData.downPayment = watch('downPayment');
    financeData.term = watch('term');
    financeData.bankID = watch('bankID');
    financeData.monthlyPayment = watch('monthlyPayment');
    if (selectedBank) {
      financeData.bankName = selectedBank?.bankName;
      financeData.interestRate = watch('interestRate');
    }

    // props.onSubmit(financeData);
  };
  return (
    <>
      <div className="finance bg-white">
        <div className="container p-4 bg-white">
          <div className="text-right">
            <div
              className="d-inline-flex align-items-center justify-content-center p-3 font-sm rounded-circle bg-gray-100"
              onClick={() => props.onCancel()}
            >
              <i className="icon-close"></i>
            </div>
          </div>
          <div className="finance-inner mx-auto">
            <h4 className="text-uppercase font-weight-normal">
              {t('build_vehicle.FINANCE')}
            </h4>
            <p className="font-normal text-gray-700  pb-3">
              {t('build_vehicle.recommented_standard_price')}
              <span className="font-weight-bold ml-1 text-gray-900">{`${props.productCurrency} ${props.netPrice}`}</span>
            </p>
            <div className="finance-form">
              <div className="row">
                <div className="col-12 form-group mb-4 pb-xl-3">
                  <label className="d-inline-flex align-items-center font-normal">
                    {t('build_vehicle.select_bank')}
                    <span className="tooltip position-relative">
                      <i className="icon-info-sm text-gray-700 font-xs ml-2 cursor-pointer"></i>
                      <div className="tooltip-content font-weight-normal text-muted font-sm position-absolute border border-secondary bg-white zIndex-1 p-3">
                        {/* Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs. */}
                      </div>
                    </span>
                  </label>
                  <>
                    <Controller
                      control={control}
                      name="bankID"
                      defaultValue={props.default?.bankID}
                      rules={{
                        required: true,
                      }}
                      render={({ onChange, name, value }) => (
                        <SelectInput
                          optionLabel="bankName"
                          optionValue="bankID"
                          placeholder={t('build_vehicle.select_bank')}
                          data={props.banks}
                          default={props.default?.bankID}
                          onChange={(val: number) => {
                            onChange(val);
                            onChangeBank(val);
                          }}
                          isSearchable={false}
                          key={`bankID`}
                        />
                      )}
                    />
                    {errors.bankID && (
                      <span className="message-validation">
                        {t('build_vehicle.bank_required')}
                      </span>
                    )}
                  </>
                </div>
                {
                  <>
                    <div className="col-sm-6 col-12 form-group mb-4 pb-xl-3">
                      <div className="range-slider dir-ltr">
                        <p className="font-normal mb-3">
                          {`KWD ${selectedBank ? watch('downPayment') : ''}`}
                        </p>

                        <Controller
                          control={control}
                          name="downPayment"
                          defaultValue={props.default?.downPayment}
                          render={({ onChange, name, value }) => {
                            return (
                              <Range
                                min={selectedBank?.minDownPayment}
                                max={selectedBank?.maxDownPayment}
                                values={[value]}
                                // onChange={(values) => this.setState({ values })}
                                onChange={(values) => {
                                  onChange(values[0]);
                                  calcMonthlyPayment();
                                }}
                                renderTrack={({
                                  props: rangeprops,
                                  children,
                                }) => (
                                  <div
                                    {...rangeprops}
                                    style={{
                                      ...rangeprops.style,
                                      height: '3px',
                                      width: '100%',
                                      backgroundColor: '#D1D1D1',
                                    }}
                                  >
                                    {selectedBank && (
                                      <div
                                        ref={rangeprops.ref}
                                        style={{
                                          height: '5px',
                                          width: '100%',
                                          borderRadius: '4px',

                                          background: getTrackBackground({
                                            values: [value],
                                            colors: ['#CC0000', '#D1D1D1'],
                                            min:
                                              selectedBank?.minDownPayment || 0,
                                            max:
                                              selectedBank?.maxDownPayment || 0,
                                          }),
                                          alignSelf: 'center',
                                        }}
                                      >
                                        {children}
                                      </div>
                                    )}
                                  </div>
                                )}
                                renderThumb={({ props: rangeprops }) => {
                                  return (
                                    <div
                                      {...rangeprops}
                                      style={{
                                        ...rangeprops.style,
                                        display: selectedBank
                                          ? 'block'
                                          : 'none',
                                      }}
                                      className="range-slider-thumb"
                                    />
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <label className="font-normal mt-3 mb-0">
                        {t('build_vehicle.down_payment')}
                      </label>
                    </div>
                    <div className="col-sm-6 col-12 form-group mb-4 pb-xl-3">
                      <div className="range-slider dir-ltr">
                        <p className="font-normal mb-3">{`${
                          selectedBank ? watch('term') : ''
                        } ${t('build_vehicle.months')}`}</p>
                        <Controller
                          control={control}
                          name="term"
                          defaultValue={props.default?.term}
                          render={({ onChange, name, value }) => (
                            <Range
                              step={selectedBank?.tenureIncValue}
                              min={selectedBank?.minimumTenure}
                              max={selectedBank?.maximumTenure}
                              values={[value]}
                              // onChange={(values) => this.setState({ values })}
                              onChange={(values) => {
                                onChange(values[0]);
                                calcMonthlyPayment();
                              }}
                              renderTrack={({
                                props: rangeprops,
                                children,
                              }) => (
                                <div
                                  {...rangeprops}
                                  style={{
                                    ...rangeprops.style,
                                    height: '3px',
                                    width: '100%',
                                    backgroundColor: '#ccc',
                                  }}
                                >
                                  {selectedBank && (
                                    <div
                                      ref={rangeprops.ref}
                                      style={{
                                        height: '5px',
                                        width: '100%',
                                        borderRadius: '4px',

                                        background: getTrackBackground({
                                          values: [value],
                                          colors: ['#CC0000', '#D1D1D1'],
                                          min: selectedBank?.minimumTenure || 0,
                                          max: selectedBank?.maximumTenure || 0,
                                        }),
                                        alignSelf: 'center',
                                      }}
                                    >
                                      {children}
                                    </div>
                                  )}
                                </div>
                              )}
                              renderThumb={({ props: rangeprops }) => {
                                return (
                                  <div
                                    {...rangeprops}
                                    style={{
                                      ...rangeprops.style,
                                      display: selectedBank ? 'block' : 'none',
                                    }}
                                    className="range-slider-thumb"
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <label className="font-normal mt-3 mb-0">
                        {t('build_vehicle.term')}
                      </label>
                    </div>
                    <div className="col-12 form-group mb-4 pb-xl-3">
                      <label className="font-normal">
                        {t('build_vehicle.finance_rate')}
                      </label>
                      <Controller
                        control={control}
                        name="interestRate"
                        defaultValue={props.default?.interestRate}
                        rules={{ required: true }}
                        render={({ onChange, name, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Rate"
                            readOnly={true}
                            value={value}
                          />
                        )}
                      />
                    </div>
                  </>
                }
                <div className="col-12 mb-4">
                  <span className="text-gray-700 font-normal d-flex mb-1">
                    {t('build_vehicle.monthly_payment')}
                  </span>
                  <Controller
                    control={control}
                    name="monthlyPayment"
                    defaultValue={props.default?.monthlyPayment}
                    rules={{ required: true }}
                    render={({ onChange, name, value }) => (
                      <h6 className="m-0">{`KWD ${
                        watch('monthlyPayment')?.toFixed(2) || 0
                      }`}</h6>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapActionsToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      dispatch,
    },
    dispatch
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: state.cartState.isLoading,
    cartData: state.cartState,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm);
