import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { FullPageLoader } from '../../components/loader';
import UnAuthenticateRoute from './UnAuthenticatedRoute';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppSettingsStateViewModel } from 'alg-ecom-frontend-core';
import SubRoutes from './SubRoutes';

const NotFound = lazy(() => import('../notfound'));

interface CustomProps {
  appSettingsState: AppSettingsStateViewModel;
}
const Routes: React.FunctionComponent<CustomProps> = (props) => {
  const { lang } = useParams<{
    lang: string;
  }>();
  // console.log('url lang===========', window.location.pathname);
  return (
    <Suspense fallback={<FullPageLoader text={'Loading...'} />}>
      <Switch>
        <Route path={'/:lang'} component={SubRoutes} />
        <Redirect
          to={{
            pathname: `${
              props.appSettingsState?.htmlDirection === 'rtl' ? 'ar' : 'en'
            }${RouteKeys.Home}`,
          }}
          from={RouteKeys.Home}
        />

        <Route exact path={RouteKeys.NotFound} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
const mapStateToProps = (state: any) => ({
  appSettingsState: state.appSettingsState,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  // redirectSave: (to: string) => {
  //   dispatch(authUserActions.redirectSave(to));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
