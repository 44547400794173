import React from 'react';
import 'assets/sass/common.scss';
import 'assets/sass/index.scss';
import ProductItem from './ProductItem';
import classnames from 'classnames';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from 'store/interfaces';
import {
  BuildPriceProductModel,
  BuildPriceActions,
  ProductListDataModel,
  api,
  BuildPriceBodyModelDataModel,
  BankListStateModel,
  bankListActions,
  ShowRoomListStateModel,
  showRoomListActions,
  BuildPriceModelsModel,
  BuildPriceBodyTypeModel,
} from 'alg-ecom-frontend-core';
import { useTranslation } from 'react-i18next';
import SubHeader from './SubHeader';
import { useDataLoader } from 'react-remote-data-hooks';
import Slider from 'react-slick';
import SliderBanners from './SliderBanners';
import ContainerLoader from 'components/loader/container-loader';
import ReactModal from 'react-modal';
import PaymentForm from 'components/payment-form/PaymentForm';
// import BookTestDrivePopUp from 'components/quick-access/test-drive/BookTestDrivePopUp';
import TrimLevelSelectionOne from './trim-level-selection-one';
import TrimLevelSelectionTwo from './trim-level-selection-two';
// import PaymentForm from 'containers/build-vehicle/finance/PaymentForm';
enum TabNames {
  All = 'All',
  Cars = 'Cars',
  Suv = 'Suv',
  Trucks = 'Trucks',
}

enum SelectionStages {
  Stage1 = 'Stage1',
  Stage2 = 'Stage2',
  Stage3 = 'Stage3',
  Stage4 = 'Stage4',
}

export const customQuickStyles: ReactModal.Styles = {
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    maxWidth: '1140px',
    width: '100%',
    border: '0',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    padding: '0',
    margin: '20px auto',
  },
  overlay: {
    zIndex: 100000,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflow: 'auto',
  },
};
export const customStyles: ReactModal.Styles = {
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    maxWidth: '540px',
    width: '100%',
    border: '0',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    padding: '0',
    margin: '20px auto',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflow: 'auto',
  },
};

const slickSettings = {
  breakpoint: 1119,
  settings: {
    slidesToShow: 2,
  },
};
const unslick = 'unslick' as const;
const sliderSettings = {
  key: 'product-slider',
  dots: false,
  draggable: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  // slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  autoplaySpeed: 2500,
  arrows: false,
  className: 'product-slider',
  responsive: [
    slickSettings,
    {
      breakpoint: 767,
      settings: unslick,
    },
  ],
};

interface CustomProps {
  productsList: BuildPriceProductModel[];
  getProductList: typeof BuildPriceActions.getProductList;
  banks: BankListStateModel;
  getBankList: typeof bankListActions.getBankList;
  showRooms: ShowRoomListStateModel;
  getShowRoomList: typeof showRoomListActions.getShowRoomList;
  getModelList: typeof BuildPriceActions.getModelList;
  updateFilter: typeof BuildPriceActions.updateFilter;
  bodytypesList?: BuildPriceBodyTypeModel[];
  modelsList?: BuildPriceModelsModel[];
  bodyModelLoaded?: boolean;
  bodyModelLoading?: boolean;
  modelyear: number;
  bodytype: string;
  productLoaded?: boolean;
  productLoading?: boolean;
  onSelectComplete: any;
  onSelectTrim: any;
}

const CarSelection: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const { t } = useTranslation();
  const [tabName, setTabName] = React.useState(props.bodytype || 'All');
  const [modelCode, setModelCode] = React.useState('');
  const [trimCode, settrimCode] = React.useState('');
  const [selectionStage, setSelectionStage] = React.useState<SelectionStages>(
    SelectionStages.Stage1
  );
  const [selectedProduct, setSelectedProduct] = React.useState<
    BuildPriceProductModel | undefined
  >(undefined);
  React.useEffect(() => {
    props.getBankList();
    props.getShowRoomList();
  }, []);
  const [isFinancialCalcActive, setIsFinancialCalcActive] = React.useState<
    boolean
  >(false);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  const [modelYear, setModelYear] = React.useState<
    | {
        modelyear: number;
      }
    | undefined
  >(
    {
      modelyear: props.modelyear,
    } || undefined
  );

  const [bodyType, setbodyType] = React.useState<
    | {
        bodyType: string;
        typeKey: string;
      }
    | undefined
  >(
    {
      bodyType: 'All Vehicles',
      typeKey: 'All',
    } || undefined
  );

  const {
    data: bodyModelData,
    reload: bodyModelReload,
    loaded: bodyModelLoaded,
    loading: bodyModelLoading,
  } = useDataLoader<BuildPriceBodyModelDataModel>((modelyear: number) =>
    api.build.modelList(modelyear)
  );

  const {
    data: bodyYearData,
    reload: bodyYearReload,
    loaded: bodyYearLoaded,
    loading: bodyYearLoading,
  } = useDataLoader<BuildPriceBodyModelDataModel>(() => api.build.modelList());

  // const { data, reload, loaded, loading } = useDataLoader<ProductListDataModel>(
  //   (year: number, tab: string) => api.build.buildPriceProductList(year, tab)
  // );
  const onModelYearChange = (value: { modelyear: number }) => {
    setModelYear(value);
    setTabName(TabNames.All);
    // reload(value.modelyear, TabNames.All);
    bodyModelReload(value.modelyear);
  };
  const onTabChangeChange = (tab: string) => {
    setTabName(tab);
    // reload(modelYear.modelyear, tab);
  };

  React.useEffect(() => {
    if (bodyYearData?.modelsList?.length > 0) {
      if (props.modelyear === undefined || props.modelyear === 0) {
        setModelYear(bodyYearData?.modelsList[0]);
        bodyModelReload(bodyYearData?.modelsList[0]);
      } else {
        setModelYear({ modelyear: props.modelyear });
        bodyModelReload(props.modelyear);
      }
    }
  }, [bodyYearData]);

  React.useEffect(() => {
    loadData();
  }, [tabName, modelYear]);

  // React.useEffect(() => {
  //   if (bodyModelData?.bodytypesList) {
  //     bodyModelData?.bodytypesList.unshift({
  //       bodyType: 'All Vehicles',
  //       typeKey: 'All',
  //     })
  //   }
  // }, [bodyModelData?.bodytypesList]);

  const loadData = async () => {
    setLoading(true);
    if (modelYear?.modelyear) {
      await props.getProductList(modelYear?.modelyear, tabName);
    }
    setLoading(false);
  };

  const onStage1Click = (modelCodeA: string) => {
    setSelectionStage(SelectionStages.Stage2);
    setModelCode(modelCodeA);
    props.onSelectTrim(modelCodeA, modelYear?.modelyear);
  };

  const onBack = (stage: SelectionStages) => {
    setSelectionStage(stage);
  };

  const onStage2Click = (
    modelCodeA: string,
    trimCodeA: string,
    productID: number,
    image: string,
    price: number,
    productTitle: string
  ) => {
    if (trimCodeA !== undefined && trimCodeA !== '') {
      setSelectionStage(SelectionStages.Stage3);
    } else {
      props.onSelectComplete(productID, image, price, productTitle);
      setSelectionStage(SelectionStages.Stage4);
    }

    setModelCode(modelCodeA);
    settrimCode(trimCodeA);
  };

  const onStage3Click = (
    productID: number,
    image: string,
    price: number,
    productTitle: string
  ) => {
    props.onSelectComplete(productID, image, price, productTitle);
  };
  if (isLoading) {
    return (
      <div className="d-flex align-items-center py-5 justify-content-center">
        <ContainerLoader />
      </div>
    );
  }
  return (
    <>
      {selectionStage === SelectionStages.Stage1 && (
        <>
          <div className="text-center border-bottom">
            <ul
              className="nav nav-pills mb-0 d-lg-inline-flex font-normal text-uppercase px-3  tab-underline  w-100 py-3"
              id="vechile-tab"
              role="tablist"
            >
              <li className="mr-2 pr-3 d-lg-inline-flex align-items-center col-sm-4 col-12">
                {bodyYearLoaded &&
                  !bodyYearLoading &&
                  bodyYearData?.modelsList.length > 0 &&
                  modelYear && (
                    <Select
                      placeholder={'Year'}
                      key={'reactModelYear'}
                      getOptionLabel={(option) => `${option.modelyear}`}
                      getOptionValue={(option) => `${option.modelyear}`}
                      options={bodyYearData?.modelsList}
                      isSearchable={false}
                      defaultValue={modelYear}
                      onChange={(value) => {
                        if (value) {
                          onModelYearChange(value);
                        }
                      }}
                      className="select-model select position-relative zIndex-9"
                    />
                  )}
              </li>

              <li className="col-sm-4 col-12 d-flex align-items-center justify-content-center">
                <h5 className="mb-0">All Vehicles</h5>
              </li>

              {/* <li className="mr-2 pr-3 d-lg-inline-flex align-items-center">
                {bodyYearLoaded &&
                  !bodyYearLoading &&
                  bodyYearData?.bodytypesList.length > 0 && (
                    <Select
                      placeholder={'Type'}
                      key={'reactType'}
                      getOptionLabel={(option) => `${option.bodyType}`}
                      getOptionValue={(option) => `${option.bodyType}`}
                      options={bodyModelData?.bodytypesList}
                      isSearchable={false}
                      defaultValue={bodyType}
                      onChange={(value) => {
                        if (value) {
                          console.log(value);
                          onTabChangeChange(value?.typeKey);
                        }
                      }}
                      className="select-model select position-relative zIndex-9"
                    />
                  )}
              </li> */}

              {/* <li
                className="nav-item pr-lg-2 pr-4"
                role="presentation"
                onClick={() => onTabChangeChange(TabNames.All)}
              >
                <a
                  className={classnames({
                    'nav-link cursor-pointer py-3': true,
                    active: tabName === TabNames.All,
                  })}
                  id="all-vehicles-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="all-vehicles"
                  aria-selected="true"
                >
                  {t('build_price.tab_all')}
                </a>
              </li>
              {bodyModelLoaded &&
                !bodyModelLoading &&
                bodyModelData?.bodytypesList
                  ?.filter((item) => item.bodyType !== '')
                  .map((tab) => {
                    return (
                      <li
                        className="nav-item pr-lg-2 pr-4"
                        role="presentation"
                        onClick={() => onTabChangeChange(tab.typeKey)}
                      >
                        <a
                          className={classnames({
                            'nav-link cursor-pointer py-3': true,
                            active: tabName === tab.typeKey,
                          })}
                          id="cars-tab"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="cars"
                          aria-selected="false"
                        >
                          {tab.bodyType}
                        </a>
                      </li>
                    );
                  })} */}
            </ul>
          </div>

          <div className="overflow-hidden">
            <div className="container vechile-tab-container">
              <div className="tab-content" id="vechile-tabContent">
                <div
                  className={classnames({
                    'tab-pane fade show': true,
                    'show active': true,
                  })}
                  id="all-vehicles"
                  role="tabpanel"
                  aria-labelledby="all-vehicles-tab"
                >
                  {/* <div className="row"> */}
                  {/* {!loaded && loading && <ContainerLoader height={20} />} */}

                  {props.productLoaded && !props.productLoading && modelYear && (
                    <div className="row product-selection-row">
                      {props?.productsList?.map((product) => {
                        return (
                          <ProductItem
                            product={product}
                            key={product.productID}
                            modelYear={modelYear.modelyear}
                            onClick={onStage1Click}
                          />
                        );
                      })}
                    </div>
                  )}

                  {props?.productsList?.length === undefined && (
                    <div className="col-12 h5 mb-5 mt-5 pb-5 pt-5 text-center">
                      <div className="mb-5">No vehicle yet.</div>
                    </div>
                  )}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {selectionStage === SelectionStages.Stage2 &&
        modelYear &&
        modelCode !== '' && (
          <>
            <div
              className="align-items-center border-bottom d-flex font-normal p-3"
              onClick={() => onBack(SelectionStages.Stage1)}
            >
              <div className="py-2 align-items-center d-flex cursor-pointer">
                <i className="font-lg icon-arrow-left mr-2 text-muted"></i> Back
              </div>
            </div>
            <TrimLevelSelectionOne
              modelCode={modelCode}
              year={modelYear?.modelyear}
              onItemClick={onStage2Click}
            />
          </>
        )}
      {selectionStage === SelectionStages.Stage3 &&
        modelYear &&
        modelCode !== '' &&
        trimCode !== '' && (
          <>
            <div
              className="align-items-center border-bottom d-flex font-normal p-3"
              onClick={() => onBack(SelectionStages.Stage2)}
            >
              <div className="py-2 align-items-center d-flex cursor-pointer">
                <i className="font-lg icon-arrow-left mr-2 text-muted"></i> Back
              </div>
            </div>
            <TrimLevelSelectionTwo
              modelCode={modelCode}
              year={modelYear?.modelyear}
              trim={trimCode}
              onItemClick={onStage3Click}
            />
          </>
        )}
      {selectionStage === SelectionStages.Stage4 && 'done'}
      {/* <TrimLevelSelectionTwo/> */}
    </>
  );
};

const mapActionsToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      dispatch,
      getProductList: BuildPriceActions.getProductList,
      getBankList: bankListActions.getBankList,
      getShowRoomList: showRoomListActions.getShowRoomList,
      getModelList: BuildPriceActions.getModelList,
      updateFilter: BuildPriceActions.updateFilter,
    },
    dispatch
  );
};

const mapStateToProps = (state: State) => {
  return {
    banks: state.bankState,
    showRooms: state.showRoomState,
    productsList: state.buildPriceReducer.productsList,
    modelsList: state.buildPriceReducer.modelsList,
    bodytypesList: state.buildPriceReducer.bodytypesList,
    bodyModelLoaded: state.buildPriceReducer.bodyModelLoaded,
    bodyModelLoading: state.buildPriceReducer.bodyModelLoading,
    modelyear: state.buildPriceReducer.modelyear,
    bodytype: state.buildPriceReducer.bodytype,
    productLoaded: state.buildPriceReducer.productLoaded,
    productLoading: state.buildPriceReducer.productLoading,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(CarSelection);
