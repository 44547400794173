import React, { Component, Dispatch } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authActions as authUserActions } from 'alg-ecom-frontend-core';
import Header from '../header';
import Footer from '../footer';
interface CustomProps {
  component: any;
  // isUserAuthenticated: () => boolean;
  // redirectSave: (pathname: string) => void;
  exact?: boolean;
  path?: string;
  showFooter: boolean;
  avoidHeader?: boolean;
  fullHeader?: boolean;
  transparent?: boolean;
  displayFooter?: boolean;
}

class UnAuthenticatedRoute extends Component<CustomProps> {
  render() {
    const {
      // isUserAuthenticated,
      // redirectSave,
      component,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          // if (!isUserAuthenticated()) {
          //   redirectSave(props.location.pathname);
          // }
          // if (isUserAuthenticated()) {
          return (
            <>
              {!this.props.avoidHeader && (
                <Header fullHeader={this.props.fullHeader} transparent={this.props.transparent} />
              )}
              <this.props.component {...props} />
              {this.props.showFooter === true && <Footer displayFooter={this.props.displayFooter} />}
            </>
          );
          // }
          // return (
          //   <Redirect
          //     to={{
          //       pathname: '/auth',
          //     }}
          //     from={props.location.pathname}
          //   />
          // );
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  // isUserAuthenticated: () => state.authUser.token != null,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  // redirectSave: (to: string) => {
  //   dispatch(authUserActions.redirectSave(to));
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnAuthenticatedRoute);
