import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { State } from './interfaces';
import {
  authReducer,
  buildPriceReducer,
  trimVarientsReducer,
  commonReducer,
  compareVarientsReducer,
  editionVarientsReducer,
  productDetailsReducer,
  cartReducer,
  combinationDetailsReducer,
  tradeInBrandReducer,
  tradeInModelReducer,
  tradeInMileageReducer,
  appSettingsReducer,
  tradeInModelYearReducer,
  bankListReducer,
  showRoomListReducer,
  allVehicleModelListReducer,
} from 'alg-ecom-frontend-core';

const rootReducer = combineReducers<State>({
  commonState: commonReducer,
  appSettingsState: appSettingsReducer,
  authState: authReducer,
  buildPriceReducer,
  trimVarientState: trimVarientsReducer,
  editionVarientState: editionVarientsReducer,
  compareVarientState: compareVarientsReducer,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  productDetailsState: productDetailsReducer,
  cartState: cartReducer,
  combinationState: combinationDetailsReducer,
  tradeinBrandState: tradeInBrandReducer,
  tradeinModelState: tradeInModelReducer,
  tradeinMileageState: tradeInMileageReducer,
  tradeinModelYearState: tradeInModelYearReducer,
  bankState: bankListReducer,
  showRoomState: showRoomListReducer,
  allVehicleState: allVehicleModelListReducer,
});

export default rootReducer;
