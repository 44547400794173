import React, { Component, Dispatch } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { State } from 'store/interfaces';
import { authActions, commonActions } from 'alg-ecom-frontend-core';
import { RouteKeys } from './route-keys';
// import { thisExpression } from '@babel/types';

interface CustomProps {
  component: any;
  isUserAuthenticated: () => boolean;
  redirectSave: (pathname: string) => void;
  redirectTo?: string;
  exact?: boolean;
  path?: string;
  user?: any;
}
const RedirectIfAuthenticatedRoute: React.FunctionComponent<CustomProps> = (
  props
) => {
  const { isUserAuthenticated, redirectSave, redirectTo, component, ...rest } =
    props;
  const { lang } = useParams<{
    lang: string;
  }>();
  return (
    <Route
      {...rest}
      render={(renderprops) => {
        if (!isUserAuthenticated()) {
          return <props.component {...renderprops} />;
        }
        if (isUserAuthenticated()) {
          redirectSave(redirectTo || `/${lang}${RouteKeys.Home}`);
        }
        return (
          <Redirect
            to={{
              pathname: `/${lang}${RouteKeys.Home}`,
            }}
            from={renderprops.location.pathname}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: State) => ({
  isUserAuthenticated: () => state.authState?.token != null,
  user: state.authState,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  redirectSave: (to: string) => {
    dispatch(authActions.redirectSave(to));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectIfAuthenticatedRoute);
