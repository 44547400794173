export enum RouteKeys {
  Home = '/',
  TrimLEvel = '/trim-level/:modelCode/',
  EditionVariant = '/edition/:year/:modelCode/:trim/',
  BuildVehicle = '/build-vehicle/:id',
  PayNow = '/pay-now/',
  PaymentCallback = '/payment-callback/',
  Auth = '/auth/',
  ForgotPassword = '/auth/forgot-password',
  MyAccount = '/my-account/',
  NotFound = '/page-not-found',
  TrimLevelVariant = '/trim-level/:modelCode/:bodyType',
  Privacy = '/privacy',
  Terms = '/terms',
}
