import React, { lazy, Suspense } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { FullPageLoader } from '../../components/loader';
import UnAuthenticateRoute from './UnAuthenticatedRoute';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import {
  api,
  AppSettingsHtmlDirectionAction,
  AppSettingsLanguageAction,
  AppSettingsStateViewModel,
} from 'alg-ecom-frontend-core';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';

const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const TrimLevel = lazy(() => import('../trim-level'));
const EditionVarient = lazy(() => import('../edition-variant'));
const BuildVehicle = lazy(() => import('../build-vehicle'));
const PaymentSelection = lazy(() => import('../payment/PayNow'));
const PaymentSuccess = lazy(() => import('../payment/PaymentSuccess'));
const AuthPage = lazy(() => import('../auth/AuthPage'));
const MyAccount = lazy(() => import('../dashboard'));
const ForgotPassowrd = lazy(
  () => import('../auth/forgot-password/ForgotPasswordPage')
);
const Privacy = lazy(() => import('../cms/privacypolicy'));
const Terms = lazy(() => import('../cms/terms'));
const TrimLevelVariant = lazy(() => import('../trim-level-variant'));

interface CustomProps {
  appSettingsState: AppSettingsStateViewModel;
}
const SubRoutes: React.FunctionComponent<CustomProps> = (props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lang } = useParams<{
    lang: string;
  }>();
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    handleLanguage(lang);
  }, [path]);

  React.useEffect(() => {
    if (lang === 'ar' || lang === 'en') {
      const data = props?.appSettingsState?.data?.languagesList.find(
        (item) => item.languageLcidstring === lang
      );

      let language =
        props.appSettingsState.htmlDirection === 'ltr' ? 'en' : 'ar';
      language = language === lang ? language : lang;

      const direction = lang === 'en' ? 'ltr' : 'rtl';

      if (data) {
        const qdirection = data.languageDirection;
        if (qdirection !== direction) {
          handleLanguage(data.languageLcidstring);
        } else {
          i18n.changeLanguage(lang);
          document
            .getElementsByTagName('html')[0]
            .setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl');
        }
      } else {
        i18n.changeLanguage(language);
        document
          .getElementsByTagName('html')[0]
          .setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl');
      }
    } else {
      handleLanguage('ar');
      history.replace({
        pathname: path.replace(':lang', 'ar'),
      });
    }
  }, [props.appSettingsState.htmlDirection]);

  const handleLanguage = (ln: string) => {
    if (
      props?.appSettingsState?.data &&
      props?.appSettingsState?.data?.languagesList?.length > 0
    ) {
      const data = props?.appSettingsState?.data?.languagesList.find(
        (item) => item.languageLcidstring === ln
      );
      if (data) {
        api.setLanguageID(data.languageID);
        dispatch(AppSettingsLanguageAction(data.languageID));
        dispatch(AppSettingsHtmlDirectionAction(ln === 'en' ? 'ltr' : 'rtl'));
      }
    }
  };
  return (
    <Suspense fallback={<FullPageLoader text={'Loading...'} />}>
      <Switch>
        {loaded && (
          <>
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.Home}`}
              component={Home}
              showFooter={true}
              transparent={true}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.TrimLEvel}`}
              component={TrimLevel}
              showFooter={true}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.TrimLevelVariant}`}
              component={TrimLevelVariant}
              showFooter={true}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.Privacy}`}
              component={Privacy}
              showFooter={true}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.Terms}`}
              component={Terms}
              showFooter={true}
            />

            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.PayNow}`}
              component={PaymentSelection}
              showFooter={false}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.PaymentCallback}`}
              component={PaymentSuccess}
              showFooter={false}
            />
            <UnAuthenticateRoute
              exact
              path={`${path}${RouteKeys.BuildVehicle}`}
              component={BuildVehicle}
              showFooter={false}
              fullHeader={true}
            />
            <RedirectIfAuthenticatedRoute
              exact
              path={`${path}${RouteKeys.Auth}`}
              component={AuthPage}
              // showFooter={false}
              // avoidHeader
            />
            <RedirectIfAuthenticatedRoute
              exact
              path={`${path}${RouteKeys.ForgotPassword}`}
              component={ForgotPassowrd}
              // showFooter={false}
              // avoidHeader
            />
            <AuthenticateRoute
              exact
              path={`${path}${RouteKeys.MyAccount}`}
              component={MyAccount}
              showFooter={false}
            />
            {/* <Route exact path={RouteKeys.Home} component={Home} /> */}
            <Route exact path={`${RouteKeys.NotFound}`} component={NotFound} />
            {/* <Route component={NotFound} /> */}
          </>
        )}
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state: any) => ({
  appSettingsState: state.appSettingsState,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  // redirectSave: (to: string) => {
  //   dispatch(authUserActions.redirectSave(to));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubRoutes);
