import React from 'react';
import {
  numberWithCommas,
  TrimVarientProductModel,
  BuildPriceProductModel,
  CartActions,
  ProductDetailsActions,
} from 'alg-ecom-frontend-core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { XmlEntities } from 'html-entities';
import { State } from 'store/interfaces';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
interface CustomProps {
  product: TrimVarientProductModel;
  isSelected: boolean;
  onItemClick: any;
  year: number;
  modelCode: string;
}

const ProductItem: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onProductClick = async (id: number, image: string) => {
    props.onItemClick(props.modelCode, props.product.trimCode, id);
    // await props.clearCart();
    // await props.clearProductDetails();
    // if (
    //   props.product.trimCode === undefined ||
    //   props.product.trimCode === null ||
    //   props.product.trimCode === ''
    // ) {
    //   history.push(`/build-vehicle/${props.product.productID}`);
    // } else {
    //   history.push(
    //     `/edition/${props.year}/${props.modelCode}/${props.product.trimCode}`
    //   );
    // }
  };
  return (
    <>
      <div
        className={classnames({
          'productBox productBox--sm border-top col-12 col-sm-6 col-md-4': true,
          active: props.isSelected,
        })}
      >
        <figure className="position-relative mx-auto text-center m-0">
          <img
            src={
              props.product.productImage !== ''
                ? props.product.productImage
                : require('assets/images/product/car.png')
            }
            className="img-fluid mx-auto position-relative zIndex-1"
            alt=""
          />
        </figure>
        <figcaption className="d-flex flex-column mb-md-4 mb-3">
          <h4 className="text-uppercase mb-3 font-base">
            {props.product.productTitle}
          </h4>

          <div className="d-flex align-items-center mb-3">
            <p className="font-normal text-muted mb-1">
              Starting at {`${props.product.productCurrency
                } ${numberWithCommas(props.product.offerPrice)}`}
            </p>
          </div>

          <div className="">
            <button
              // to={`/build-vehicle/${props.product.productID}`}
              onClick={() =>
                onProductClick(
                  props.product.productID,
                  props.product.previewImage
                )
              }
              className="btn btn-primary px-4 font-sm text-uppercase productBox__select"
            >
              Build Yours
                  </button>
          </div>
        </figcaption>
      </div>
    </>
  );
};
const mapActionsToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      dispatch,
      clearCart: CartActions.clearCart,
      clearProductDetails: ProductDetailsActions.clearProductDetails,
    },
    dispatch
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(ProductItem);
