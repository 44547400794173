export default class Config {
  static readonly MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoic2hhcmFmdWRoZWVuIiwiYSI6ImNrNHNsMGpjYTA1dDAzZG93a2tnNzV3cnEifQ.hZ0R4NowNN6YzbhOCZFGQg';
  static GOOGLE_ANALYTICS_ID = 'UA-155985479-1';
  static franchise = 'bronco';
  // static franchise = 'ford';
  static FACEBOOK_APP_ID = '386263629273837';
  static GOOGLE_CLIENT_ID =
    '299150888981-e52b8e7gq6f702jv607l1irfg8tiip88.apps.googleusercontent.com';
  static Password_Min = '6';
  static Password_Max = '15';
}
