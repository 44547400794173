import React from 'react';
import 'assets/sass/common.scss';
import 'assets/sass/trim-level.scss';
import ProductItem from './ProductItem';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from 'store/interfaces';
import {
  api,
  bankListActions,
  BankListStateModel,
  showRoomListActions,
  ShowRoomListStateModel,
  TrimVarientActions,
  TrimVarientDataViewModel,
  TrimVarientProductModel,
  BuildPriceProductModel,
} from 'alg-ecom-frontend-core';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import SubHeader from './SubHeader';
import MobileTabs from './MobileTabs';
import { useParams } from 'react-router';
import { useDataLoader } from 'react-remote-data-hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PaymentForm from 'components/payment-form/PaymentForm';
// import BookTestDrivePopUp from 'components/quick-access/test-drive/BookTestDrivePopUp';

enum TabNames {
  All = 'All',
  Cars = 'Cars',
  Suv = 'Suv',
  Trucks = 'Trucks',
}
const years = [
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
  { value: '2018', label: '2018' },
];

const unslick = 'unslick' as const;

const sliderSettings = {
  key: 'product-slider',
  dots: false,
  draggable: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  // slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  autoplaySpeed: 2500,
  arrows: false,
  className: 'product-slider d-lg-block d-flex flex-wrap',
  responsive: [
    {
      breakpoint: 1119,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 991,
      settings: unslick,
    },
  ],
};

interface CustomProps {
  isLoading: boolean;
  trimData: TrimVarientDataViewModel;
  getTrimVarients: typeof TrimVarientActions.getTrimVarients;
  productsList: BuildPriceProductModel[];
  productLoaded?: boolean;
  modelCode: string;
  year: number;
  onItemClick: any;
}

const TrimLevelSelectionOne: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  // const { year, modelCode } = useParams<{ year: string; modelCode: string }>();
  const [trimLoaded, setTrimLoaded] = React.useState(false);

  const [productTitle, setProductTitle] = React.useState('');
  const [trimList, setTrimList] = React.useState<TrimVarientProductModel[]>();
  const [selectedProduct, setSelectedProduct] = React.useState<
    TrimVarientProductModel | undefined
  >(undefined);

  const [
    isFinancialCalcActive,
    setIsFinancialCalcActive,
  ] = React.useState<boolean>(false);
  const [isTestDriveActive, setIsTestDriveActive] = React.useState<boolean>(
    false
  );
  // const { data, reload, loaded, loading } = useDataLoader<
  //   TrimVarientDataViewModel
  // >(() => api.build.trimList(parseInt(year), modelCode));
  const { t } = useTranslation();
  const [tabName, setTabName] = React.useState(TabNames.All);
  const [selectedVarient, setSelectedVarient] = React.useState<
    TrimVarientProductModel | undefined
  >(undefined);
  const [iFrameUrl, setIFrameUrl] = React.useState('');
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (!trimLoaded) {
      loadTrimList();

      scrollTop();
    }
  }, []);

  const loadTrimList = async () => {
    setTrimLoaded(true);

    if (
      props.productLoaded &&
      props?.productsList &&
      props?.productsList?.length
    ) {
      const products = props?.productsList?.find(
        (x) => x.modelCode === props?.modelCode
      );

      if (products) {
        setTrimList(products.products_model);
        setProductTitle(products.productTitle);
        setSelectedVarient(
          products?.products_model?.length > 0
            ? products?.products_model?.[0]
            : undefined
        );
      }
    } else {
      const data = await api.build.trimList(props.year, props.modelCode);
      setTrimList(data?.productsList);
      setProductTitle(data?.pageTitle);
      setSelectedVarient(
        data?.productsList?.length > 0 ? data?.productsList?.[0] : undefined
      );
    }
  };

  return (
    <>
      {/* {trimList && <SubHeader headerText={productTitle} />} */}

      <div className="product-list  overflow-hidden">
        <div className="container  px-lg-3 px-0">
          <div className="row product-selection-row">
            {/* <Slider {...sliderSettings}> */}
            {trimList &&
              trimList?.map((product) => {
                return (
                  <ProductItem
                    product={product}
                    key={product.productID}
                    onItemClick={() =>
                      props.onItemClick(
                        props.modelCode,
                        product.trimCode,
                        product.productID,
                        product.productImage,
                        product.salesPrice,
                        product.productTitle
                      )
                    }
                    isSelected={
                      selectedVarient?.productID === product.productID
                    }
                    year={props.year}
                    modelCode={props.modelCode}
                  />
                );
              })}
            {/* </Slider> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapActionsToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      dispatch,
      getTrimVarients: TrimVarientActions.getTrimVarients,
      getBankList: bankListActions.getBankList,
      getShowRoomList: showRoomListActions.getShowRoomList,
    },
    dispatch
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: state.trimVarientState.isLoading,
    trimData: state.trimVarientState.data,
    banks: state.bankState,
    showRooms: state.showRoomState,
    productsList: state.buildPriceReducer.productsList,
    productLoaded: state.buildPriceReducer.productLoaded,
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TrimLevelSelectionOne);
